<template>
  <div class="create-brand" v-cloak>
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-projects-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></adminvue-projects-vue>
        <not-found v-else></not-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import adminvue from "./edit/admin-vue";
import noComponent from "../../components/noComponentFound";
// import salesteamleadvue from "./overview/salesteamlead_overview";
// import salespersonvue from "./overview/salesperson_overview";
// import clientvue from "./overview/client_overview";
// import defaultvue from "./overview/default_overview";

export default {
  components: {
    "adminvue-projects-vue": adminvue,
    "not-found": noComponent
    // "clientvue-projects-vue": clientvue,
    // "default-projects-vue": defaultvue
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  }
};
</script>
