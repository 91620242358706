<template>
  <div class="viewDashBoard project-route-index">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else class="white-row">
        <div class="show-project-portal-listing">
          <!-- Create Project Form  -->
          <div class="form-area">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row top-gen-heading">
                    <h1 class="global-style-heading">
                      Edit Brand
                    </h1>
                  </div>
                </div>
                <div class="col-sm-12">
                  <form
                    class="brand-form"
                    v-on:submit.prevent="onSubmit($event)"
                  >
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_name"
                              >Brand Name: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Brand Name"
                              v-model="formData.brand_name"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_name.$dirty &&
                                  !$v.formData.brand_name.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_prefix"
                              >Brand Prefix: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Brand Prefix"
                              v-model="formData.brand_prefix"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_prefix.$dirty &&
                                  !$v.formData.brand_prefix.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_url"
                              >Brand URL: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Url"
                              v-model="formData.brand_url"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_url.$dirty &&
                                  (!$v.formData.brand_url.required ||
                                    !$v.formData.brand_url.url)
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_image_url"
                              >Brand Logo URL: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Url"
                              v-model="formData.brand_image_url"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_contact"
                              >Brand Contact: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Brand Contact"
                              v-model="formData.brand_contact"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_contact.$dirty &&
                                  !$v.formData.brand_contact.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="form-group">
                            <label for="currency_id" class="control-label"
                              >Brand Currency <span>*</span>
                            </label>
                            <Select2
                              v-model="formData.currency_id"
                              :options="currency_options"
                              :settings="{
                                placeholder: 'Select Currency'
                              }"
                              :class="{
                                'input-border-errors':
                                  $v.formData.currency_id.$dirty &&
                                  !$v.formData.currency_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="gender" class="control-label"
                              >Select Account Manager<span>*</span></label
                            >
                            <Select2
                              :options="am_options"
                              :settings="{
                                placeholder: 'Select Account Manager',
                                allowClear: true
                              }"
                              v-model="formData.brand_am"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_am.$dirty &&
                                  !$v.formData.brand_am.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_front_team" class="control-label"
                              >Brand Front Team</label
                            >
                            <Select2
                              :options="am_options"
                              :settings="{
                                placeholder: 'Brand Front Team',
                                allowClear: true,
                                multiple : true,
                              }"
                              v-model="formData.brand_front_team"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="brand_address"
                              >Brand Address: <span>*</span></label
                            >
                            <textarea
                              class="form-control"
                              placeholder="Brand Address"
                              v-model="formData.brand_address"
                              :class="{
                                'input-border-errors':
                                  $v.formData.brand_address.$dirty &&
                                  !$v.formData.brand_address.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label class="control-label" for="terms_and_condition">Terms and Condtition <span>*</span></label>
                            <ckeditor
                              v-model="formData.terms_and_condition"
                              :config="editorConfig"
                              :class="{
                                'input-border-errors':
                                  $v.formData.terms_and_condition.$dirty &&
                                  !$v.formData.terms_and_condition.required
                              }"
                            ></ckeditor>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-actions">
                            <button
                              :disabled="disableSubmitBtn"
                              type="submit"
                              id="save-form"
                              class="btn btn-primary default-global-btn"
                            >
                              Update
                            </button>
                            <div
                              v-if="disableSubmitBtn"
                              class="form_submit_loader"
                            >
                              <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  components: {
    Select2
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  validations: {
    formData: {
      brand_name: {
        required
      },
      brand_url: {
        required,
        url
      },
      brand_am: {
        required
      },
      currency_id: {
        required
      },
      brand_prefix: {
        required
      },
      terms_and_condition : {
        required
      },
      brand_address : {
        required
      },
      brand_contact : {
        required
      }
    }
  },
  data() {
    return {
      currency_options: null,
      loader: true,
      am_options: null,
      formData: {
        brand_name: null,
        brand_prefix: null,
        brand_url: null,
        brand_image_url : null,
        brand_am: null,
        currency_id : null,
        terms_and_condition : null,
        brand_address : null,
        brand_contact : null,
        brand_front_team : null,
      },
      disableSubmitBtn: false,
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"]
          },
          { name: "links" },
          { name: "styles" },
          { name: "colors" },
          { name: "tools" },
          { name: "others" },
          { name: "about" }
        ],
        // The configuration of the editor.
        extraPlugins: "font",
        versionCheck: false,
      },
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getemployees();
    this.getbranddetails();
    this.getCurrency();
    this.loader = false;
  },
  methods: {
    async getCurrency() {
      const $this = this;
      const currnecy = $this.$store.dispatch("getHttp", {
        url: "filters/get-curriens"
      });
      await currnecy.then(function(response) {
        if (typeof response !== "undefined") {
          $this.currency_options = response.data.data;
        }
      });
    },
    async getbranddetails() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "get-all-brands/edit/" + $this.$route.params.brand_id
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          $this.formData.brand_name = response.data.data.name;
          $this.formData.brand_url = response.data.data.url;
          $this.formData.brand_am = response.data.data.AccountManager;
          $this.formData.brand_image_url = response.data.data.brand_image_url;
          $this.formData.brand_front_team = response.data.data.brand_front_team;
          $this.formData.terms_and_condition = response.data.data.terms_and_condition;
          $this.formData.brand_contact = response.data.data.brand_contact;
          $this.formData.brand_address = response.data.data.brand_address;
          $this.formData.brand_prefix = response.data.data.brand_prefix;
          $this.formData.currency_id = response.data.data.currency_id;
        }
      });
    },
    async getemployees() {
      const $this = this;
      await window.axios
        .get("/filters/get-employees")
        .then(resps => {
          $this.am_options = resps.data.data;
        })
        .catch(function(error) {
          $this.$store.dispatch("displayMessageErrors", {
            response: error.response,
            formEvent: null
          });
          $this.loader = false;
        });
    },
    async onSubmit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();

      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }

      const data = $this.$store.dispatch("postHttp", {
        url: "get-all-brands/update/" + this.$route.params.brand_id,
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: event.target
          });
          $this.$router.push({ path: "/brands" });
          $this.disableSubmitBtn = false;
        }
      });
      $this.disableSubmitBtn = false;
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .form-area .form-group label span {
  color: red;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 100%;
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
      span.select2.select2-container.select2-container--default {
        width: 100% !important;
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.client-projects {
        padding-bottom: 0px;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: 231px;
          margin-bottom: 30px;
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 15px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
